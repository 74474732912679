/* ****************
 * GENERATED CODE *
 **************** */
import { BasicEvent } from "@h4x/common";
import { HewSyncList } from "../../system/HewSyncList";
import { HewSync } from "../../system/HewSync";
import { BaseMember } from "../base/Member";
import type { User } from "./Account";
import { UserOrganization } from "./Organization";

@HewSync.Type({
	scope: "user",
	name: "Member",
	table: "user",
	parent: UserOrganization,
	prefix: "MEMBER"
})
export class UserMember extends BaseMember {
	public static readonly type = "UserMember";
	public readonly type = "UserMember";

	@HewSync.Field({ prefix: "ORGANIZATION", reference: "Organization", type: "ID" })
	public override readonly organization: HewSync.ID<UserOrganization>;

	@HewSync.Field({ prefix: "ACCOUNT", reference: "Account", type: "ID" })
	public override readonly account: HewSync.ID<User>;

	@HewSync.Field({ default: "{}", type: "JSON" })
	public readonly settings: any;

	private static cache = new Map<string, UserMember>();

	public static get(account: HewSync.ID<User>, organization: HewSync.ID<UserOrganization>) {
		this.initSubscriptions();

		let key = this.getKey(account, organization);
		let item = this.cache.get(key)!;

		if (item === undefined) {
			item = new UserMember({
				account: account.value,
				organization: organization.value,
				createdAt: "",
				updatedAt: "",
				name: "",
				settings: "{}"
			});
			this.cache.set(key, item);

			void (async () => {
				await HewSync.authPromise;
				item.fetch([
					new HewSync.QueryVariable("account", "ID", account.value),
					new HewSync.QueryVariable("organization", "ID", organization.value)
				]);
			})();
		}

		return item;
	}

	protected reset() {
		this.createdAt = "";
		this.updatedAt = "";
		this.name = "";
		this.settings = "{}";
	}

	/**
	 * Raw get function, bypasses cache
	 *
	 * Do not use this function on the frontend, use get instead
	 */
	public static rawGet(account: HewSync.ID<User>, organization: HewSync.ID<UserOrganization>) {
		return HewSync.queryGet<typeof UserMember, UserMember>(
			UserMember,
			[
				new HewSync.QueryVariable("account", "ID", account.value),
				new HewSync.QueryVariable("organization", "ID", organization.value)
			],
			false
		);
	}

	public static list(
		filter?: Partial<UserMember> | ((data: UserMember) => boolean)
	): HewSyncList<typeof UserMember, UserMember> {
		if (typeof filter === "object") {
			let list = new HewSyncList<typeof UserMember, UserMember>(this, (data) => {
				let result = true;
				for (let key in filter) {
					if (Array.isArray(filter[key])) {
						if (filter[key].length !== data[key].length || !filter[key].every((x, i) => x === data[key][i])) {
							result = false;
							break;
						}
					} else if (filter[key] instanceof HewSync.ID) {
						if (data[key].value !== filter[key].value) {
							result = false;
							break;
						}
					} else if (data[key] !== filter[key]) {
						result = false;
						break;
					}
				}
				return result;
			});

			return list;
		} else {
			let list = new HewSyncList<typeof UserMember, UserMember>(this, filter);

			return list;
		}
	}

	public static listBy(
		params?: Partial<Pick<UserMember, "account" | "organization">>
	): HewSyncList<typeof UserMember, UserMember> {
		let list = new HewSyncList<typeof UserMember, UserMember>(this, undefined, params);

		return list;
	}

	public static attach(
		account: HewSync.ID<User>,
		organization: HewSync.ID<UserOrganization>,
		data: { name: string; settings?: any; roles?: string[] }
	) {
		this.initSubscriptions();
		let inputs = [
			new HewSync.QueryVariable("account", "ID", account.value),
			new HewSync.QueryVariable("organization", "ID", organization.value)
		];
		if (data.name !== undefined) {
			inputs.push(new HewSync.QueryVariable("name", "String", data.name));
		}
		if (data.settings !== undefined) {
			inputs.push(new HewSync.QueryVariable("settings", "AWSJSON", JSON.stringify(data.settings)));
		}
		if (data.roles !== undefined) {
			inputs.push(new HewSync.QueryVariable("roles", "[String]", data.roles));
		}
		return HewSync.mutationAttach<typeof UserMember, UserMember>(UserMember, inputs);
	}

	public static detach(account: HewSync.ID<User>, organization: HewSync.ID<UserOrganization>, target?: UserMember) {
		return HewSync.mutationDetach<typeof UserMember, UserMember>(
			UserMember,
			[
				new HewSync.QueryVariable("account", "ID", account.value),
				new HewSync.QueryVariable("organization", "ID", organization.value)
			],
			target
		);
	}

	public detach() {
		return UserMember.detach(this.account, this.organization, this);
	}

	public static batchDetach(items: { account: string; organization: string }[]) {
		let inputs: HewSync.QueryVariable[] = [new HewSync.QueryVariable("items", "[User_MemberKey]", items)];
		return HewSync.mutationBatchDetach<typeof UserMember, UserMember>(UserMember, inputs);
	}

	public static update(
		account: HewSync.ID<User>,
		organization: HewSync.ID<UserOrganization>,
		data: { name?: string; settings?: any },
		target?: UserMember
	) {
		let inputs = [
			new HewSync.QueryVariable("account", "ID", account.value),
			new HewSync.QueryVariable("organization", "ID", organization.value)
		];
		if (data.name !== undefined) {
			inputs.push(new HewSync.QueryVariable("name", "String", data.name));
		}
		if (data.settings !== undefined) {
			inputs.push(new HewSync.QueryVariable("settings", "AWSJSON", JSON.stringify(data.settings)));
		}
		return HewSync.mutationUpdate<typeof UserMember, UserMember>(UserMember, inputs, target);
	}

	public update(data: { name?: string; settings?: any }) {
		return UserMember.update(this.account, this.organization, data, this);
	}

	get id() {
		return new HewSync.ID<UserMember>(this.account.value);
	}

	/* eslint-disable */
	protected constructor(data: any) {
		super(Symbol.for("HewSyncType::Internal::Create") as unknown as void);

		(this as any).createdAt = new HewSync.Timestamp(data.createdAt);
		(this as any).updatedAt = new HewSync.Timestamp(data.updatedAt);
		(this as any).organization = new HewSync.ID<any>(data.organization);
		(this as any).name = data.name;
		(this as any).account = new HewSync.ID<any>(data.account);
		(this as any).settings = JSON.parse(data.settings);
	}

	public get key() {
		return this.account.value + "/" + this.organization.value;
	}

	protected apply(data: any) {
		(this as any).createdAt = new HewSync.Timestamp(data.createdAt);
		(this as any).updatedAt = new HewSync.Timestamp(data.updatedAt);
		(this as any).name = data.name;
		(this as any).settings = JSON.parse(data.settings);

		if (data.removed) {
			(this.removed as any) = true;
			this.onRemove.execute(this);
		} else {
			this.onUpdate.execute(this);
		}
	}
	/* eslint-enable */

	protected static from(data: any): UserMember {
		return new UserMember(data);
	}

	public static getKey(account: HewSync.ID<User>, organization: HewSync.ID<UserOrganization>) {
		return account.value + "/" + organization.value;
	}

	public static getKeyFromData(data: { account: string; organization: string }) {
		return `${data.account + "/" + data.organization}`;
	}

	private static onSubscriptionEvent = new BasicEvent<(data: any) => void>();

	private static subscriptionsInitialized = false;
	static initSubscriptions() {
		if (this.subscriptionsInitialized === false) {
			void HewSync.initSubscriptions(this, this.onSubscriptionEvent);
			this.subscriptionsInitialized = true;
		}
	}
}
