/* ****************
 * GENERATED CODE *
 **************** */
import { BasicEvent } from "@h4x/common";
import { HewSyncList } from "../../system/HewSyncList";
import { HewSync } from "../../system/HewSync";
import { BaseType } from "../../system/BaseType";

// Abstract
export class BaseAccount extends BaseType {
	@HewSync.Field({ createOnly: true, type: "ID" })
	public override readonly id: HewSync.ID<BaseAccount>;

	@HewSync.Field({ type: "String" })
	public readonly name: string;

	@HewSync.Field({ type: "Timestamp" })
	public readonly lastEmailChangeAt: HewSync.Timestamp;

	@HewSync.Field({ type: "Timestamp" })
	public readonly lastPasswordChangeAt: HewSync.Timestamp;

	@HewSync.Field({ type: "Timestamp" })
	public readonly lastLoginAt: HewSync.Timestamp;
}
